import React, { useContext, useRef, useState, useEffect } from "react";
import { CoursesContext } from "context/coursesContext";
import { MeetingsContext } from "context/meetingsContext";
import { teacherLinks } from "./teacherLinks";

import { ModalPremium } from "components/ModalPremium";
import { ConfirmationModal } from "components/Speaking-components/confirmation-modal";
import TeachersList from "components/Speaking-components/teacher-list";
import TopicsList from "components/Speaking-components/topics-list";
import { Calendar } from "components/Speaking-components/calendar";
import { UpgradePlanPage } from "components/UpgradePlanPage";
import "../../styles/Calendar.css";

const SpeakingPage = ({ requiredPlanFeature }) => {
  const { courses, userPlan } = useContext(CoursesContext);
  const { appointmentsAvailable } = useContext(MeetingsContext);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isSchedulingModuleOpen, setIsSchedulingModuleOpen] = useState(false);
  const [topicToSchedule, setTopicToSchedule] = useState(null);
  const [teachersArray, setTeachersArray] = useState(null);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [hasRequiredPlan, setHasRequiredPlan] = useState(false);

  const goToTop = () => {
    Page.current.scrollIntoView();
  };

  const Page = useRef(null);

  const handleConfirmScheduling = async (confirmed) => {
    if (confirmed) {
      let tempTeachersArray = [];
      let [obj] = teacherLinks.filter(
        (obj) => obj.id === topicToSchedule.courseID
      );

      obj.teachers.forEach((teacher) => {
        let [topic] = teacher.topics.filter(
          (topic) => topic.id === topicToSchedule.topic.position
        );

        if (!!topic) {
          let teacherObj = { name: teacher.name, link: topic.link };
          tempTeachersArray.push(teacherObj);
        }
      });

      setTeachersArray(tempTeachersArray);
      setIsSchedulingModuleOpen(true);
      goToTop();
    }

    setIsConfirmationModalOpen(false);
  };

  const openConfirmation = (courseID, topic) => {
    setTopicToSchedule({ courseID, topic });
    setIsConfirmationModalOpen(true);
  };

  useEffect(() => {
    setHasRequiredPlan(
      userPlan?.features?.find(
        (item) => item.featureName === requiredPlanFeature && item.enabled
      )
    );
  }, [userPlan]);

  useEffect(() => {
    if (!isSchedulingModuleOpen) {
      window.scrollTo(0, 0);
    }
  }, [isSchedulingModuleOpen]);

  return hasRequiredPlan ? (
    <div className="calendar-container pt-8 pb-14 p-5 bg-[#1B212B]" ref={Page}>
      <div className="blue-box pr-6 py-6 text-center text-[20px]">
        <h1 className="main-title text-[#7DB8F2] text-[1.2em] font-[700]">
          Schedule your speaking sessions
        </h1>
        <p className="text-md w-full py-4 text-[#7DB8F2]">
          Practical sessions available this month:{" "}
          <strong>{appointmentsAvailable}</strong>
        </p>
      </div>

      <ModalPremium isModalOpen={false} />

      {isSchedulingModuleOpen ? (
        selectedTeacher ? (
          <Calendar
            selectedTeacher={selectedTeacher}
            closeCalendar={() => {
              setSelectedTeacher(null);
            }}
            goBackToTopics={() => {
              setIsSchedulingModuleOpen(false);
            }}
          />
        ) : (
          <TeachersList
            teachersArray={teachersArray}
            selectedTeacher={selectedTeacher}
            setSelectedTeacher={setSelectedTeacher}
            topicToSchedule={topicToSchedule}
            closeList={() => {
              setIsSchedulingModuleOpen(false);
            }}
          />
        )
      ) : (
        <TopicsList
          courses={courses}
          openConfirmation={openConfirmation}
          appointmentsAvailable={appointmentsAvailable}
          isSchedulingModuleOpen={isSchedulingModuleOpen}
        />
      )}

      {isConfirmationModalOpen && (
        <ConfirmationModal
          appointmentsAvailable={appointmentsAvailable}
          onConfirm={handleConfirmScheduling}
        />
      )}
    </div>
  ) : (
    <UpgradePlanPage />
  );
};

export default SpeakingPage;

const TeachersList = ({
  topicToSchedule,
  teachersArray,
  selectedTeacher,
  setSelectedTeacher,
  closeList,
}) => {
  return (
    <div className="flex flex-col justify-start items-center">
      <p className="text-xl my-2 mb-10 mt-4 mb:mt-8 text-[#7DB8F2] font-semibold text-center w-full">
        Schedule the session:{" "}
        <span className="text-[#7DB8F2]">{topicToSchedule.topic.name}</span>
      </p>

      <strong className="text-lg text-[#7DB8F2]">Select a coach</strong>
      <p className="text-white mb-8">Select only one coach!</p>

      <div className="w-full max-w-md mb-4">
        {teachersArray !== null &&
          teachersArray.map((teacher) => {
            return (
              <div
                key={teacher.name}
                className={
                  "flex justify-center border border-transparent items-center bg-white rounded-lg shadow p-4 px-10 hover:border-teal-400 cursor-pointer mb-2"
                }
                onClick={() => {
                  setSelectedTeacher(teacher.link);
                }}
              >
                <p>{teacher.name}</p>
              </div>
            );
          })}
      </div>
      <button
        className="p-2 px-8 rounded-full bg-[#CF7831] hover:opacity-75  text-white font-semibold w-fit mb-4"
        onClick={closeList}
      >
        Go back
      </button>
    </div>
  );
};

export default TeachersList;

import { useState, useEffect, useContext } from "react";


import Accordion from "components/Accordion";
import { CoursesContext } from "context/coursesContext";
import { getAllAditionalMaterial } from "services/getAllAditionalMaterial";
import { UpgradePlanPage } from "components/UpgradePlanPage";

export default function AdditionalMaterialPage({ requiredPlanFeature }) {
  const [refuerzo, setRefuerzo] = useState([]);
  const { courses, userPlan } = useContext(CoursesContext);
  const [hasRequiredPlan, setHasRequiredPlan] = useState(false);

  useEffect(() => {
    setHasRequiredPlan(
      userPlan?.features?.find(
        (item) => item.featureName === requiredPlanFeature && item.enabled
      )
    );
  }, [userPlan]);

  const fetchAditionalMaterial = async () => {
    let filteredCourses = courses.filter((course) => course.PlanEstudio !== "Obligatorio" && course.planEstudio !== "Freemium");
    filteredCourses = filteredCourses.filter((course) => course.planEstudio.includes('plus')).length > 0 ? filteredCourses.filter((course) => course.planEstudio.includes('plus')) : filteredCourses;
    const ids = filteredCourses.map((course) => course.id);
    const res = await getAllAditionalMaterial();
    const aditionalMaterials = res.aditionalMaterial.filter((material) =>
      ids.includes(material.cursoId)
    );
    setRefuerzo(aditionalMaterials);
  };

  useEffect(() => {

    if (courses.length <= 0) return;
    fetchAditionalMaterial();
  }, [courses]);

  if (hasRequiredPlan === undefined) {
    return null;
  }

  return (hasRequiredPlan ?
    <section className="flex flex-col gap-5 p-5 md:p-10 lg:px-14">
      <h2 className="text-[#7DB8F2] text-2xl font-semibold">
        Additional Material
      </h2>
      <Accordion items={refuerzo} />
    </section>
    :
    <UpgradePlanPage />
  );
}

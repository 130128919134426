import TopicCard from "pages/Speaking/TopicCard";
import { SkeletonTopicCard } from "pages/Speaking/skeletonTopicCard"
import { useEffect, useState, useCallback } from "react";
import { getSpeakingTopics } from "services/getSpeakingTopics";
const TopicsList = ({
  courses,
  openConfirmation,
  appointmentsAvailable,
  isSchedulingModuleOpen,
}) => {
  const [topics, setTopics] = useState([]);

  const fetchTopics = async (courseId) => {
    try {
      const courseTopics = await getSpeakingTopics();
      return { courseId, topics: courseTopics };
    } catch (error) {
      console.error(`Error fetching topics for course ${courseId}:`, error);
      return null;
    }
  };

  const updateTopics = useCallback(async () => {
    let updatedTopics = {};
  
    await Promise.all(
      courses.map(async (course) => {
        if (
          course.planEstudio !== "Obligatorio" &&
          course.planEstudio !== "Freemium" &&
          !course.planEstudio.includes("plus")
        ) {
          const result = await fetchTopics(course.id);
          if (result) {
            updatedTopics[result.courseId] = result.topics;
          }
        }
      })
    );
  
    setTopics(updatedTopics);
  }, [courses]);

  useEffect(() => {
    fetchTopics();
    updateTopics();
  },[updateTopics]);

  return (
    <div className="grid w-full grid-cols-1 lg:grid-cols-1 gap-4 gap-y-8">
      {courses.length > 0 ? (
        courses.map((course) => {
          return (
            <div key={course.id}>
              {topics[course.id] && (
                <div className="flex flex-col justify-start">
                  <strong className="text-[#7DB8F2] text-2xl font-bold mb-6">
                    {course.nombre} Topics
                  </strong>
                  <div className="grid grid-cols-1 xl:grid-cols-4 gap-4">
                    {topics[course.id].map((topic, i) => (
                      <TopicCard
                        key={topic.name + "-" + topic.id}
                        {...topic}
                        appointmentsAvailable={appointmentsAvailable}
                        courseID={course.id}
                        openConfirmation={openConfirmation}
                        isSchedulingModuleOpen={isSchedulingModuleOpen}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          );
        })
      ) : (
        <div className="flex flex-col justify-start">
          <strong className="text-[#7DB8F2] text-2xl font-bold mb-6">
            Topics
          </strong>
          <div className="grid grid-cols-1 xl:grid-cols-4 gap-4">
            {Array.from({ length: 8 }).map((_, index) => (
              <SkeletonTopicCard key={index} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TopicsList;

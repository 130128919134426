import React, { useRef, useState, useContext } from "react";
import { firestore } from "../../firebase/credentials";
import { USER_ID } from "services/settings";
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { MeetingsContext } from "context/meetingsContext";
import "../../styles/Calendar.css";

export const Calendar = ({ selectedTeacher, closeCalendar, goBackToTopics }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { runOperations, appointmentsAvailable } = useContext(MeetingsContext);
  const Iframe = useRef(null);

  const updateFirebase = async () => {
    setIsLoading(true);
    const now = await updatedTime();
    let date = new Date(Number(now.toString() + "000"));
    let obj = {
      appointmentsLeft: appointmentsAvailable - 1,
    };
    const meetingsRef = doc(firestore, "meetings", `${USER_ID}`);
    await setDoc(meetingsRef, obj, { merge: true });
    await runOperations();
    setIsLoading(false);
    // closeCalendar();
  };

  const updatedTime = async () => {
    const timeRef = doc(firestore, "meetings", "serverTime");
    await updateDoc(timeRef, {
      timestamp: serverTimestamp(),
    });
    const docSnap = await getDoc(timeRef);
    const currentTime = docSnap.data().timestamp.seconds;
    return currentTime;
  };



  return (
    <>
      <iframe
        ref={Iframe}
        src={`${selectedTeacher}?embed=1&embedType=iframe`}
        loading="lazy"
        style={{
          border: "none",
          minWidth: "320px",
          minHeight: "544px",
        }}
        className={"iframe-calendar-speaking"}
        id="zcal-invite"
        title="zcal"
      ></iframe>
      <div className="flex flex-col justify-start items-center -mt-0">
        <strong className="text-lg my-2 mb-6 mt-8 text-[#7DB8F2]">
          Have you scheduled the session?
        </strong>
        <div className="flex ">
          <button
            className="p-2 px-8 rounded-full bg-green-400 hover:bg-green-500 text-white font-semibold w-fit mr-2"
            onClick={updateFirebase}
          >
            {isLoading ? "Loading..." : "Yes, confirm my session"}
          </button>
          <button
            className="p-2 px-8 rounded-full bg-red-600 hover:bg-red-700  text-white font-semibold w-fit"
            onClick={closeCalendar}
          >
            No, I'm checking the availability
          </button>
        </div>

        <strong className="text-lg my-2 mb-6 mt-8 text-[#7DB8F2]">
          Are the available hours not suited to your needs?
        </strong>
        <button
          className="p-2 px-8 rounded-full bg-red-600 hover:bg-red-700  text-white font-semibold w-fit mb-4"
          onClick={goBackToTopics}
        >
          Go back
        </button>
      </div>
    </>
  );
};

export const ConfirmationModal = ({onConfirm, appointmentsAvailable }) => {
  return (
    <div
      className="confirmation-modal-speaking opacity-0 fixed top-0 min-h-screen bg-[#1B212B] flex justify-center items-center"
      style={{
        backgroundColor: "#94a3b840",
        animation: ".3s ease-in-out forwards appear",
      }}
    >
      <div className="h-min flex flex-col justify-center text-center items-center p-6 md:px-10 rounded-lg bg-[#1B212B]">
        <>
          <p className="text-[#7DB8F2]">
            Do you want to schedule this session?
          </p>
          <strong className="text-[#7DB8F2] mb-5">
            (It will be deducted from your {appointmentsAvailable} monthly sessions)
          </strong>

          <div className="flex">
            <button
              className="p-2 px-8 rounded-full bg-[#7DB8F2] hover:opacity-75 text-white font-semibold w-fit mr-2"
              onClick={() => onConfirm(true)}
            >
              Yes
            </button>
            <button
              className="p-2 px-8 rounded-full bg-[#CF7831] hover:opacity-75  text-white font-semibold w-fit"
              onClick={() => onConfirm(false)}
            >
              Not now
            </button>
          </div>
        </>
      </div>
    </div>
  );
};
